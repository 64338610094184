.body {
	padding-top: 15px;

	.infoContainer {
		display: flex;
		align-items: center;
		margin-top: 5px;

		.icon {
			margin-right: 10px;
		}
	}
}
