@import '../../../../styles/entrypoint.scss';

.body {
	@include modalBody;
	max-height: 100%;

	@include smallScreen {
		width: 100%;
	}

	.container {
		padding: 0 15px 15px;

		@include smallScreen {
			padding-bottom: 0;
		}

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: sticky;
			top: 0;
			background-color: #fff;
			padding: 15px 0;
			z-index: 1;

			h2 {
				font-family: game-regular;
				font-size: $h2Size;

				@include smallScreen {
					font-size: $h2SizeMobile;
				}
			}
		}

		.spiner {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 50px 0;
		}

		.notAvailable {
			margin: 15px 0;
			text-align: center;
		}
	}
}
