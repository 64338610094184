@import '../../styles/entrypoint.scss';

.body {
  h1 {
    font-family: game-regular;
    font-size: $h1Size;
    text-align: center;
    margin: 20px 0;

    @include smallScreen {
      font-size: $h1SizeMobile;
    }
  }
}