@import '../../../../styles/entrypoint.scss';

.body {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;

	@include smallScreen {
		flex-direction: column;
		width: 100%;
	}

	.saveData {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include smallScreen {
			width: 100%;
			margin-bottom: 30px;
		}

		.icon {
			font-size: 20px;
			margin-left: 8px;
			position: relative;
			top: 3px;
		}
	}

	.button {
		@include smallScreen {
			width: 100%;
			height: 50px;
		}
	}
}
