@import '../../../../styles/entrypoint.scss';

.body {
  padding: 15px 15px 0;

  .checkbox {
    @include smallScreen {
      margin: 30px 0 15px;
    }
  }

  .modalText {
    margin-bottom: 15px;
  }
}