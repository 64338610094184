@import '../../../../styles/entrypoint.scss';

.body {
	display: flex;
	justify-content: space-between;
	align-items: center;
  padding: 5px 0;

	@include smallScreen {
		padding: 15px 0;
	}

	&:hover {
		background-color: $disabledColorBgc;
	}

	.name {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
	}

	.counter {
		display: flex;
		justify-content: center;
		align-items: center;

		.number {
			font-size: 15px;
			margin: 0 15px;
		}
	}

	.iconButton {

		@include smallScreen {
			height: 40px;
			width: 40px;
      border: $border;
		}
	}
}
