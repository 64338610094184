@import '../../../../styles/entrypoint.scss';

.body {
	margin: 15px;
	padding: 15px;
	width: 120px;
	height: 120px;
	border-radius: $borderRadius;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: segoe-bold;

	@include smallScreen {
		width: 100%;
    margin: 15px 0;
	}

	img {
		height: 50px;
		width: 50px;
	}

	.categoryName {
		margin-top: 15px;
	}
}

.isActive {
	color: $textColor;
	border: $border;
	background-color: #fff;

	&:hover {
		color: #000;
		background-color: $disabledColorBgc;
		cursor: pointer;
	}
}

.isDisabled {
	color: $textColorDisabled;
	border: $disabledColorBgc;
	background-color: $disabledColorBgc;

	img {
		filter: invert(70%);
	}
}
