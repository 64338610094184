@import '../../styles/entrypoint.scss';

.body {
	width: 50%;
	margin: 15px;
	padding: 15px;
	box-sizing: border-box;

	@include smallScreen {
		width: 100%;
		margin: 15px 0;
		padding: 15px 0;
	}

	h2 {
		font-size: $h2Size;
		margin-bottom: 15px;
		font-family: game-regular;

		@include smallScreen {
			font-size: $h2SizeMobile;
			text-align: center;
		}
	}

	.categoriesContainer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;

		@include smallScreen {
			justify-content: center;
		}

		.spinnerContainer {
			display: flex;
			justify-content: center;
			width: 100%;
		}
	}
}
