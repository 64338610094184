@import '../../styles/entrypoint.scss';

.buttonOutput {
	@include smallScreen {
		margin: 0 0 15px 0;
		width: 300px;
	}
}

.buttonInput {
	@include smallScreen {
		text-align: center;
	}
}
