@import '../../../../styles/entrypoint.scss';

.body {
	@include modalBody;

	@include smallScreen {
		width: 100%;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: sticky;
		top: 0;
		background-color: #fff;
		padding: 15px;

		h2 {
			font-family: game-regular;
			font-size: $h2Size;

			@include smallScreen {
				font-size: $h2SizeMobile;
			}
		}
	}

	.container {
		padding: 0 15px;
		
		.fail {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			margin: 50px 0;
		}
	}
}
