@import '../../styles/entrypoint.scss';

.body {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include smallScreen {
		justify-content: center;
		flex-direction: column;
	}

	.imgContainer {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 15px;

		@include smallScreen {
			width: 100%;
			margin-right: 0px;
		}

		img {
			height: 650px;

			@include smallScreen {
				height: 400px;
			}
		}
	}

	.textContainer {
		width: 50%;
		display: flex;
		flex-direction: column;
		margin-left: 15px;

		@include smallScreen {
			width: 100%;
			margin-left: 0px;
		}

		h1 {
			font-family: game-regular;
			font-size: $h1Size;
			text-align: center;
			margin: 0;
			
			@include smallScreen {
				font-size: $h1SizeMobile;
			}
		}

		p {
			font-size: 20px;

			@include smallScreen {
				font-size: 15px;
				margin: 15px 0 0 0;
			}
		}

		.buttonWrapper {
			margin: 30px 0;
			@include smallScreen {
				display: flex;
				justify-content: center;
			}
		}
	}
}
