@font-face {
	font-family: game-regular;
	src: url(./fonts/PressStart2P-Regular.ttf);
}

@font-face {
	font-family: segoe-regular;
	src: url(./fonts/segoe-ui.ttf);
}

@font-face {
	font-family: segoe-bold;
	src: url(./fonts/segoe-ui-gras.ttf);
}

@font-face {
	font-family: yarin;
	src: url(./fonts/Yarin-Regular.otf);
}

* {
	box-sizing: border-box;
}

body {
	margin: 0 auto;
	padding: 0;
	max-width: 1500px;
	font-size: 15px;
	font-family: segoe-regular;
}

.ms-DetailsHeader-cellName {
	font-size: 15px;
}

.ms-DetailsRow-cell {
	font-size: 15px;
}

.ms-Spinner--large {
	width: 50px;
	height: 50px;
}

.ms-TextField-field {
	font-size: 15px;
}

@media screen {
	@media (max-width: 500px) {
		.ms-TextField-fieldGroup {
			height: 50px;
			display: flex;
			align-items: center;

			input {
				height: 50px;
				padding: 0 20px;
			}
		}

		.ms-DatePicker {
			width: 283px;

			.root-153 {
				width: 250px;
			}

			.ms-FocusZone {
				width: 250px;

				table {
					width: 260px;
				}

				th {
					font-size: 15px !important;
					padding: 5px;
				}

				td {
					padding: 5px;

					span {
						font-size: 15px !important;
					}
				}
			}
		}
	}
}
