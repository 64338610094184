@import '../../styles/entrypoint.scss';

.body {
	display: flex;
	justify-content: center;
	align-items: center;

	@include smallScreen {
		flex-direction: column;
	}

	.pickerWrapper {
		display: flex;
		align-items: center;
		width: 350px;

		@include smallScreen {
			width: 100%;
		}
	}

	.picker {
		width: 300px;
		margin: 15px;

		@include smallScreen {
			width: 100%;
		}
	}

	.icon {
		font-size: 20px;
	}
}
