@import '../../styles/entrypoint.scss';

.body {
	width: 300px;
	margin: 15px;

	@include smallScreen {
		margin: 15px 0;
		width: 100%;
	}

	.container {
		border: $border;
		padding: 15px;
		border-radius: $borderRadius;
		min-height: 155px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include smallScreen {
			width: 100%;
			margin: 15px 0;
		}

		h2 {
			font-size: $h2Size;
			margin-bottom: 15px;
			font-family: game-regular;

			@include smallScreen {
				font-size: $h2SizeMobile;
				text-align: center;
			}
		}

		.emptyText {
			height: 32px;
			display: flex;
			align-items: center;
		}

		.cartItem {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 0;
			border-bottom: $border;
		}

		.totalAmount {
			height: 32px;
			margin-top: 15px;
			font-family: segoe-bold;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}
