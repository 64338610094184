@import '../../styles/entrypoint.scss';

.body {
	width: 300px;
	margin: 15px 15px 60px 15px;

	@include smallScreen {
		width: 100%;
	}

	.container {
		padding: 15px;
		border: $border;
		border-radius: $borderRadius;
		min-height: 155px;

		@include smallScreen {
			width: 100%;
			margin: 15px 0;
		}

		h2 {
			font-size: $h2Size;
			margin-bottom: 15px;
			font-family: game-regular;

			@include smallScreen {
				font-size: $h2SizeMobile;
				text-align: center;
			}
		}

		.row {
			margin-top: 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.payBtnContainer {
		display: flex;
		justify-content: center;
		padding: 15px 15px 0;

		.payBtn {
			width: 100%;

			@include smallScreen {
				height: 50px;
			}
		}
	}
}
