@import '../../../../styles/entrypoint.scss';

.body {
	@include modalBody;

	@include smallScreen {
		width: 100%;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: sticky;
		top: 0;
		background-color: #fff;
		padding: 15px;

		h2 {
			font-family: game-regular;
			font-size: $h2Size;

			@include smallScreen {
				font-size: $h2SizeMobile;
			}
		}
	}

	.description {
		padding: 15px;

		img {
			height: 30px;
			margin-top: 15px;
		}
	}

	.textFieldContainer {
		display: flex;
		padding: 15px;

		@include smallScreen {
			flex-direction: column;
			padding-top: 0;
		}

		.input {
			font-size: 15px;
			text-align: center;

			@include smallScreen {
				width: 100%;
				font-size: 25px;
			}
		}

		.textFieldCard {
			width: 165px;
			margin-right: 15px;

			@include smallScreen {
				width: 100%;
			}
		}

		.textFieldExpDate {
			width: 80px;

			@include smallScreen {
				width: 100%;
			}
		}
	}

	.addDataButtonContainer {
		margin: 15px;

		.addDataButton {
			width: 100%;

			@include smallScreen {
				height: 50px;
			}
		}
	}
}
