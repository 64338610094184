@import '../../../../styles/entrypoint.scss';

.body {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 15px;

	@include smallScreen {
		padding: 15px 0;
	}

	.notAvailable {
		margin: 15px 0;
		text-align: center;
	}

	.hintBtn {
		margin-bottom: 15px;

		@include smallScreen {
			height: 50px;
		}
	}

	.hint {
		margin-bottom: 15px;
	}

	.addToCartBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
		width: 100%;

		@include smallScreen {
			height: 50px;
		}
	}
}
