@import '../../../../styles/entrypoint.scss';

.body {
	@include modalBody;
	max-height: 100%;

	@include smallScreen {
		width: 100%;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: sticky;
		top: 0;
    background-color: #fff;
    padding: 15px;

		h2 {
			font-family: game-regular;
			font-size: $h2Size;

			@include smallScreen {
				font-size: $h2SizeMobile;
			}
		}
	}

	.description {
		padding: 15px;
	}

	.textField {
		margin: 15px;
	}
}
