@import '../../../../styles/entrypoint.scss';

.body {
	font-size: 15px;
  overflow: hidden;
  max-width: 1000px;
  margin: 15px;

	@include smallScreen {
		width: 100%;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: sticky;
		top: 0;
    background-color: #fff;
    padding: 15px;

		h2 {
			font-family: game-regular;
			font-size: $h2Size;

			@include smallScreen {
				font-size: $h2SizeMobile;
			}
		}
	}

	.container {
		padding: 0 15px 15px;
    margin-bottom: 50px;

		.title {
			font-family: segoe-bold;
		}
	}
}
