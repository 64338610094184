@import '../../styles/entrypoint.scss';

.body {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include smallScreen {
		justify-content: center;
		flex-direction: column;
	}

	.logoContainer {
		display: flex;
		align-items: center;

		&:hover {
			cursor: pointer;
		}

		.logoImg {
			img {
				height: 70px;

				@include smallScreen {
					height: 50px;
				}
			}
		}

		.logoText {
			margin: 0 15px;
			font-family: game-regular;
			font-size: 25px;

			@include smallScreen {
				text-align: center;
				font-size: 20px;
			}
		}
	}

	.buttonContainer {
		display: flex;

		@include smallScreen {
			flex-direction: column;
			align-items: center;
			margin: 15px 0 0 0;
		}

		.buttonWrapper {
			margin-left: 15px;

			@include smallScreen {
				margin: 0;
			}
		}

		.languageSelector {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			margin-left: 15px;
			font-family: segoe-bold;

			div {
				&:hover {
					color: $primaryColor;
					cursor: pointer;
				}
			}

			.selected {
				color: $primaryColor;
			}

			.notSelected {
				color: $textColor;
			}

			@include smallScreen {
				margin-left: 0;
				margin-bottom: 15px;
				flex-direction: row;
				width: 100px;
			}
		}
	}
}
