@import '../../../../styles/entrypoint.scss';

.inputWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 15px;

	@include smallScreen {
		width: 100%;
		margin: 15px 0;
	}

	.input {
		font-size: 25px;
		width: 135px;
		text-align: center;

		@include smallScreen {
			width: 100%;
		}
	}
}

.button {
	margin: 15px 0;
	width: 100%;

	@include smallScreen {
		height: 50px;
	}
}
