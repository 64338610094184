// animation
$transition: 0.2s;

// borders
$borderRadius: 2px;
$border: 1px solid #605e5c;

// colors
$primaryColor: #0078d4;
$textColor: #323130;
$textColorDisabled: #a19f9d;
$borderColor: #605e5c;
$disabledColorBgc: #f3f2f1;

// fontSize
$h1Size: 20px;
$h1SizeMobile: 15px;
$h2Size: 15px;
$h2SizeMobile: 13px;