@import '../../styles/entrypoint.scss';

.body {
	width: 100%;
	border-top: 1px solid #d6d6d6;
	border-bottom: 1px solid #d6d6d6;
	padding: 15px 0;
	display: flex;
	justify-content: space-between;

	@include smallScreen {
		justify-content: center;
		flex-direction: column;
		border-bottom: none;
	}

	h2 {
		font-family: game-regular;
		font-size: $h2Size;

		@include smallScreen {
			font-size: $h2SizeMobile;
		}
	}

	.textBlock {
		padding: 0 15px;
		width: 50%;

		@include smallScreen {
			width: 100%;
			padding-bottom: 15px;
			margin-bottom: 15px;
			border-bottom: 1px solid #d6d6d6;
		}

		img {
			height: 20px;
			margin-right: 15px;
		}

		.socialText {
			display: flex;
			align-items: center;
			margin: 15px 0;
		}
	}
}

.credits {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 30px 0;
	
	@include smallScreen {
		font-size: 13px;
		padding: 0 0 30px;
	}

	.easterEgg {
		height: 100px;
	}

	.dev {
		display: flex;
		align-items: center;

		.text1 {
			font-family: yarin;
			font-size: 30px;
			margin-right: 10px;

			@include smallScreen {
				margin-right: 5px;
				font-size: 25px;
			}
		}

		.text2 {
			width: 100px;
			position: relative;
			bottom: 3px;
		}
	}
}
