@import './styles/entrypoint.scss';

.body {
  padding: 40px 40px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  @include smallScreen {
		padding: 20px 20px 0 20px;
	}
}