@import '../../styles/entrypoint.scss';

.body {

	.remainderContainer {
		padding: 0 15px 15px 15px;
		display: flex;
		justify-content: center;
		align-items: center;

		@include smallScreen {
			padding: 0 0 15px 0;
		}

		.remainder{
			padding: 10px;
			width: 500px;

			@include smallScreen {
				width: 100%;
			}
		}
	}

	.container {
		display: flex;
		justify-content: space-between;

		@include smallScreen {
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
}
